/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
*/

$(function() {

    'use strict';

    var navItem = $('.navigation__link[href^="#"]'),
        doc = $('html, body');

    navItem.on('click', function(event) {
        var target = this.hash,
            nav = $('#navbar'),
            navOffset = nav.height() - 25;
        event.preventDefault();
        doc.animate({
            scrollTop: $(target).offset().top - navOffset
        }, 800, function() {
            return window.history.pushState(null, null, target);
        });
    });
});
