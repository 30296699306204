/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */

 $(function () {
    'use strict';

    // Display menu mobile
    $('.menu-open').on('click', e => {
        e.preventDefault();

        $('#menu').addClass('active');
        $('html, body').addClass('overflow-hidden');
    });

    // Hide menu mobile
    $('.menu-close').on('click', e => {
        e.preventDefault();

        $('#menu').removeClass('active');
        $('html, body').removeClass('overflow-hidden');
    });

    // Add active class to links from navbar
    $('.link-hash').on('click', (e) => {
        $('.link-hash').removeClass('active');
        $(e.currentTarget).addClass('active');
    });

    let link = location.hash;

    $("a[href='" + link + "']").addClass('active');

    // Display dropdown menu
    $('.menu-dropdown-show').on('click', (e) => {
        $('.menu-dropdown-show').toggleClass('active');
        $('.menu-dropdown').toggleClass('active');
    });

    // Hide menu.
    $(document).keyup((e) => {
        if (e.key === 'Escape' && window.innerWidth > 767) {
        $('.menu-dropdown-show').removeClass('active');
        $('.menu-dropdown').removeClass('active');
        }
    });
 });
