const modal = require('../support/Modal');

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(function () {
    'use strict';

    if ($('#registerModal').length > 0) {
        modal.trigger($('#registerModal'));
    }
});
