/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(function () {
    'use strict';

    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

    if ($('#btnShopifyCart').length > 0) {
        loadScript();
    }

    function loadScript() {
        var script = document.createElement('script');

        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        script.onload = ShopifyBuyInit;
    }

    function ShopifyBuyInit() {

        var client = ShopifyBuy.buildClient({
                domain: 'a9497f.myshopify.com',
                storefrontAccessToken: '9c44bdff16298a55e422d9c97ef6bded',
            });

        ShopifyBuy.UI.onReady(client).then(function (ui) {
            ui.createComponent('product', {
                id: $('#btnShopifyCart').data('value'),
                node: document.getElementById('btnShopifyCart'),
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: {
                    "product": {
                        "styles": {
                          "product": {
                            "@media (min-width: 601px)": {
                              "max-width": "calc(25% - 20px)",
                              "margin-left": "50px",
                              "margin-bottom": "50px"
                            },
                            "text-align": "left"
                          },
                          "button": {
                            "font-family": "Arial, sans-serif",
                            "font-size": "14px",
                            "font-weight": "600",
                            "padding-top": "15px",
                            "padding-bottom": "15px",
                            "width": "100%",
                            ":hover": {
                              "color": "#000000",
                              "background-color": "#fab607",
                              "transition": "all .4s ease-in-out",
                            },
                            "background-color": "#fab607",
                            ":focus": {
                              "background-color": "#fab607"
                            },
                            "border-radius": "0px",
                          },
                          "quantityInput": {
                            "font-size": "14px",
                            "padding-top": "15px",
                            "padding-bottom": "15px"
                          }
                        },
                        "contents": {
                          "img": false,
                          "title": false,
                          "price": false
                        },
                        "text": {
                          "button": "Add to cart"
                        }
                    },
                    "productSet": {
                        "styles": {
                            "products": {
                                "@media (min-width: 601px)": {
                                    "margin-left": "-20px"
                                }
                            }
                        }
                    },
                    "modalProduct": {
                        "contents": {
                            "img": false,
                            "imgWithCarousel": true,
                            "button": false,
                            "buttonWithQuantity": true
                        },
                        "styles": {
                            "product": {
                                "@media (min-width: 601px)": {
                                    "max-width": "100%",
                                    "margin-left": "0px",
                                    "margin-bottom": "0px"
                                }
                            },
                            "button": {
                                "font-family": "Arial, sans-serif",
                                "font-size": "14px",
                                "padding-top": "15px",
                                "padding-bottom": "15px",
                                ":hover": {
                                    "background-color": "#e1a406"
                                },
                                "background-color": "#fab607",
                                ":focus": {
                                    "background-color": "#e1a406"
                                },
                                "border-radius": "0px",
                                "padding-left": "50px",
                                "padding-right": "50px"
                            },
                            "quantityInput": {
                                "font-size": "14px",
                                "padding-top": "15px",
                                "padding-bottom": "15px"
                            }
                        },
                        "text": {
                            "button": "Add to cart"
                        }
                    },
                    "option": {},
                    "cart": {
                        "styles": {
                            "button": {
                                "font-family": "Arial, sans-serif",
                                "font-size": "14px",
                                "padding-top": "15px",
                                "padding-bottom": "15px",
                                ":hover": {
                                    "background-color": "#e1a406"
                                },
                                "background-color": "#fab607",
                                ":focus": {
                                    "background-color": "#e1a406"
                                },
                                "border-radius": "0px"
                            }
                        },
                        "text": {
                            "total": "Subtotal",
                            "button": "Checkout"
                        }
                    },
                    "toggle": {
                        "styles": {
                            "toggle": {
                                "font-family": "Arial, sans-serif",
                                "background-color": "#fab607",
                                ":hover": {
                                    "background-color": "#e1a406"
                                },
                                ":focus": {
                                    "background-color": "#e1a406"
                                }
                            },
                            "count": {
                                "font-size": "14px"
                            }
                        }
                    }
                },
            });
        });
    }
});
