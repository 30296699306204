import Zoomist from 'zoomist';

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */

$(function () {
    'use strict';

    if ($('#zoomist').length > 0) {
        const zoomist = new Zoomist('.zoomist-container');
    }
});
