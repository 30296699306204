require('slick-carousel/slick/slick');

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */

$(function () {
    'use strict';

    /**
     * Carousel slick
     * @link http://kenwheeler.github.io/slick
     */
    // Carousel from venue: gallery - 6 elements.
    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    });

    $('.slider-nav').slick({
        slidesToShow: 7,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: true,
        centerMode: true,
        focusOnSelect: true
    });

    // Carousel from home: featured - 1 element.
    if ($(document).width() <= 1023) {
        $('.slider-home').slick({
            lazyLoad: 'ondemand',
            infinite: false,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.8,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1.2,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    // Carousel from home: packages - 1 element only mobile.
    if ($(document).width() <= 1023) {
        $('.slider-home-packages').slick({
            lazyLoad: 'ondemand',
            autoplay: true,
            autoplaySpeed: 5000,
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    }

    $('.slider-single').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
    });
});
