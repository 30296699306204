import './bootstrap';
import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

/*
 |--------------------------------------------------------------------------
 | Custom Support
 |--------------------------------------------------------------------------
 |
 | Here we'll set up the custom components, plugins, and section specific
 | javascript files that are going to be needed to run our application
 | this is a nice place to require all sorts of custom jQuery code.
 |
 */

require('./support/Collapse');
require('./support/Scrollspy');
require('./support/Modal');
require('./support/ShopifyCart');
require('./support/Tab');
require('./support/Zoomist');

/*
 |--------------------------------------------------------------------------
 | Custom Elements
 |--------------------------------------------------------------------------
 |
 | Here we'll set up the custom components, plugins, and section specific
 | javascript files that are going to be needed to run our application
 | this is a nice place to require all sorts of custom jQuery code.
 |
 */

require('./html/image');
require('./html/menu');
require('./html/modals');
require('./html/sliders');
require('./html/share');

/*
 |--------------------------------------------------------------------------
 | Setup The Vue Instance
 |--------------------------------------------------------------------------
 |
 | Next, we will create a fresh Vue application instance and attach it to
 | the page. Then, you may begin adding components to this application
 | or customize the JavaScript scaffolding to fit your unique needs.
 |
 */

import { createApp } from 'vue'
import GoogleMap from './components/GoogleMap.vue';

createApp({
    el: '#app',
    components: {
        'google-map': GoogleMap,
    }
}).mount("#app");
