/*
 | Script to make modal work on the site.
 */
module.exports = class modal {
    static trigger(element) {
        element.addClass('show');
        $('body, html').addClass('overflow-hidden');
    }
};

/*
 |--------------------------------------------------------------------------
 | Document Ready Function
 |--------------------------------------------------------------------------
 */
$(function () {
    'use strict';

    // Remove class 'show' for modal hidde when user clicked close button or  on backdrop.
    $('.modal-close, [data-backdrop="true"] .modal-backdrop').click((e) => {
        e.preventDefault();
        let modalItem = $(e.currentTarget).attr('data-miss') || $(e.currentTarget).parent();

        $(modalItem).addClass('fadeOut');
        $('body, html').removeClass('overflow-hidden');

        setTimeout((_) => {
            $(modalItem).removeClass('fadeOut').removeClass('show');
        }, 700);
    });

    // Remove class 'show' for modal hidde when user keydown "esc".
    $(document).keyup((e) => {
        if ($('[ignore-escape="true"]').length) {
            return;
        }

        if (e.key === 'Escape' && $(document).width() > 767 && $('.modal.show').length > 0) {
            $('.modal').addClass('fadeOut');
            $('body, html').removeClass('overflow-hidden');

            setTimeout((_) => {
                $('.modal').removeClass('fadeOut').removeClass('show');
            }, 700);
        }
    });
});
